@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Extralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik";
  src: url("./Graphik/Graphik-Super.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Capian";
  src: url("./Capian/Capian-Regular.woff2") format("woff2"), url("./Capian/Capian-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Capian";
  src: url("./Capian/Capian-Bold.woff2") format("woff2"), url("./Capian/Capian-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
